// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

if ($('body').hasClass('homepage')) {
  $('.homeIntroWrapper').insertBefore('.carousel');
  $('.homeFeedsWrapper').insertAfter('.homeFeatures');
  $('body').addClass('ready');
}

$('.NewsletterSign-up').insertBefore('#pageFooterWrapper');

if ($('body[class*="PostBody"]').length) {
  $('.headerText').prependTo('.contentBlockWrapper:last-of-type');
  $('.postAside').insertAfter('.contentBlockWrapper:last-of-type');
  $(window).scroll(function(){
    let scrolltop = $('html').scrollTop()/2 + 'px';
    $('.carousel').css('transform', `translateY(${scrolltop})`);
  })
}

// Related posts as cards
if ($('.associatedRelatedPosts .associatedListing li')) {
  $('.associatedRelatedPosts .associatedListing li').each(function () {
    $(this).addClass('listedPost');
    $(this).find('h3, p').wrapAll('<div class="listedPostText">');
    let thisURL = $(this).find('h3 a').attr('href');
    $(this).attr('onclick', `window.location = "${thisURL}"`);
    $(this).find('h3').replaceWith(function () {
      return '<h2>' + $(this).text() + '</h2>';
    })
  })
}

// Appeal fixes
if ($('body').hasClass('appealsPostBody')) {
  $('.appealActionsWrapper').insertAfter('.headerText');
}

// Size header items
$(document).ready(function(){
  if ($(window).width() > 1024) {
    let height = parseInt($('.headerContent #siteSearch').css('height'));
    let searchButtonHeight = height - 2;
    $('.mainCallToActionButtons > a, .socialIcons li a').css('height', `${height}px`);
    $('.socialIcons li a').css('width', `${height}px`);
    $('.searchContainer button').css({
      'height': `${searchButtonHeight}px`,
      'width': `${searchButtonHeight}px`
    });
    $('#siteSearch').css('margin-right', `-${height - 1}px`);
  }
  $('.mainSiteLink').first().insertBefore('.mainLogo');
})

// https://raisingit.atlassian.net/browse/DES-5614
$('.mainLogo').attr('href', 'https://www.stedwardsoxford.org/');